import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["password"]

    connect() {
        console.log('studyguide#connect')

        document.addEventListener("scroll", function() {
            if (window.scrollY < 2) {
                document.body.classList.remove('scrolling')
            } else {
                document.body.classList.add('scrolling')
            }
        });


        // copywrite protection
        document.oncontextmenu = this.copyrightMessage;
        document.onselectstart = new Function("return false");
    }

    copyrightMessage() {
        alert('This is copyrighted material of DMV Study Guides, Inc.  Any copying or reproduction is strictly forbidden without written consent.')
        return false;
    }

    changeFontSize(ev) {
        ev.preventDefault()

        let amt = event.params.amount,
            r = document.querySelector(':root'),
            rs = getComputedStyle(r),
            fontSize = parseInt(rs.getPropertyValue('--base-font-size'));

        // prevent from getting too small
        if (fontSize < 12) {
            fontSize = 12;
        }

        r.style.setProperty('--base-font-size', fontSize + amt + 'px');
    }

    print(ev) {
        ev.preventDefault();

        window.print();
    }

    translate(ev) {
        ev.preventDefault();

        document.getElementById('translate_trigger').style.display = 'none';
        document.getElementById('google_translate_element').style.display = 'block';
    }

}