import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf';

export default class extends Controller {
    static values = { updateUrl: String }

    connect() {
        console.log('answers#connect')
    }

    checked(ev) {
        console.log('answers#checked', this.updateUrlValue, ev.currentTarget.checked)
        const notyf = new Notyf({position: {x:'right',y:'top'}});
        fetch(this.updateUrlValue, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": getMetaValue("csrf-token")
                },
                body: JSON.stringify({ "is_correct": ev.currentTarget.checked })
            })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.is_correct) {
                    notyf.success("Answer marked as CORRECT");    
                } else {
                    notyf.error("Answer marked as incorrect");    
                }
                
            })
    }
}

function getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
}