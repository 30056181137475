import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie';

export default class extends Controller {

  connect() {
    console.log('cookies#connect')
  }

  markAsComplete(ev) {
    let key = ev.params.key;

    console.log('cookies#markAsComplete', key);
    Cookies.set(key, 'true', 365);
  }
}
