import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { multiple: Boolean, answered: Boolean };

  connect() {
    console.log("questions#connect");

    document.addEventListener("scroll", function () {
      if (window.scrollY < 2) {
        document.body.classList.remove("scrolling");
      } else {
        document.body.classList.add("scrolling");
      }
    });

    // copywrite protection
    document.oncontextmenu = this.copyrightMessage;
    document.onselectstart = new Function("return false");
  }

  copyrightMessage() {
    alert(
      "This is copyrighted material of DMV Study Guides, Inc.  Any copying or reproduction is strictly forbidden without written consent."
    );
    return false;
  }

  changeFontSize(ev) {
    ev.preventDefault();

    let amt = event.params.amount,
      r = document.querySelector(":root"),
      rs = getComputedStyle(r),
      fontSize = parseInt(rs.getPropertyValue("--base-font-size"));

    // prevent from getting too small
    if (fontSize < 12) {
      fontSize = 12;
    }

    r.style.setProperty("--base-font-size", fontSize + amt + "px");
  }

  translate(ev) {
    ev.preventDefault();

    document.getElementById("translate_trigger").style.display = "none";
    document.getElementById("google_translate_element").style.display = "block";
  }

  selected(ev) {
    if (this.answeredValue) return;

    let answers = document.getElementsByClassName("answer"),
      value = [];

    if (!this.multipleValue) {
      // only allow one selection
      for (let i = 0; i < answers.length; i++) {
        answers[i].classList.remove("selected");
      }
    }

    // first set the current answer to selected
    ev.currentTarget.classList.toggle("selected");

    // update answers and update the form value
    answers = document.getElementsByClassName("answer selected");
    for (let i = 0; i < answers.length; i++) {
      value.push(answers[i].dataset.id);
    }

    document.getElementById("answers").value = value;
    document.getElementById("submitButton").classList.add("visible");
  }
}
