import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static targets = ["topic", "location"];

  connect() {
    console.log("hello#connect");
  }

  showHelp(ev) {
    ev.preventDefault();

    gtag("event", "show_help");

    window.Beacon("open");
  }

  updateTopic(event) {
    const radio = event.target || event;
    this.topicTarget.value = radio.value;
  }

  changeStates(ev) {
    ev.preventDefault();

    let option = ev.currentTarget.selectedOptions[0],
      url = option.getAttribute("data-url"),
      name = option.getAttribute("data-state-name");

    console.log("changeStates", url, name);
    if (url) {
      gtag("event", "change_states", {
        state_name: name,
        event_callback: function () {
          // window.location.href = url;
        },
      });
    }
  }

  captureCTA(ev) {
    ev.preventDefault();

    console.log(this.topicTarget.value, this.locationTarget.value);

    if (this.locationTarget.value == "") {
      const notyf = new Notyf({
        duration: 4000,
        position: { x: "center", y: "bottom" },
      });

      notyf.error({
        message: "Please select a state",
        dismissible: true,
      });

      return;
    }

    let url = `/dmv/${this.locationTarget.value}/${this.topicTarget.value}`;
    if (this.topicTarget.value == "cdl") {
      url = "/cdl";
    }

    console.log("changeStates", url);
    if (url) {
      gtag("event", "topic_and_location", {
        state_name: this.locationTarget.value,
        event_callback: function () {
          window.location.href = url;
        },
      });
    }
  }
}
