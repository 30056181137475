import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('lightbox#connect')
  }

  zoom(ev) {
    let url = ev.target.dataset.imgUrl, 
    el = document.createElement('img');

    el.classList.add('image_zoom');
    el.classList.add('img-fluid');
    el.setAttribute('src', url);

    let card = document.createElement('div');
    card.classList.add('card');
    card.appendChild(el)

    let overlay = document.createElement('div');
    overlay.classList.add('overlay');
    overlay.appendChild(card)

    overlay.addEventListener('click', function() {
      overlay.remove();
    })

    document.body.appendChild(overlay);

  }
}
