import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        console.log('cards#connect')
    }

    selected(ev) {
        let url = ev.params.url;
        console.log('cards#selected', url)
        location.href = url;
    }

    practiceTestSelected(ev) {
        if (ev.currentTarget.classList.contains('pending')) {
          alert('Please complete the previous practice tests before proceeding to a new one.')
        } else {
            let url = ev.params.url;
            console.log('cards#selected', url)
            location.href = url;
        }
    }
}